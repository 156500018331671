(function ($) {
    // vars
    var mainToggler = '.js-menu-toggle',
        mainMenu = $('#js-main-menu'),
        subMenuToggler = '.js-sub-menu-toggler',
        subMenuClose = '.js-close-submenu'
    
    if (mainMenu.hasClass('active')) {
        $(mainToggler).addClass('opened')
    }

    $(document).on('click', mainToggler, function() {
        mainMenu.toggleClass('active')
        $(mainToggler).toggleClass('opened')
    })

    $(document).on('click', subMenuToggler, function() {
        var currentNav = $(this).parent().find('.js-megamenu')

        if (currentNav.length > 0) {
            currentNav.addClass('active-mobile')
        }
    })

    $(document).on('click', '#js-main-menu a', function() {
        if ($(this).not(subMenuToggler)) {
            mainMenu.toggleClass('active')
            $(mainToggler).toggleClass('opened')
        }
    })

    $(document).on('click', subMenuClose, function() {
        var currentNav = $(this).parents('.js-megamenu')

        if (currentNav.length > 0) {
            currentNav.removeClass('active-mobile')
        }
    })

})(jQuery);