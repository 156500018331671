(function ($) {
    // Scroll menu
    var navbar = $('#main-navbar')
    //init on load
    scrollMenu()
  
    // init on scroll
    $(window).scroll(function() {    
        scrollMenu()
    });
  
    function scrollMenu() {
        var scroll = $(window).scrollTop();
        if (scroll >= 50) {
            navbar.addClass("scrolled");
        } 
        else {
            navbar.removeClass("scrolled");
        }
    }

    if ($('.page-head__nav').length === 0) {
        return;
    } 
    else {
        var postion = $('.page-head__nav').offset().top,
            height = $('.page-head__nav').height();

        $(document).on('scroll', function () {
            var scroll = $(document).scrollTop();
            if (scroll > postion) {
                $('.page-head__nav').addClass('fixed')
            } 
            else {
                $('.page-head__nav').removeClass('fixed')
            }
        });
    };


})(jQuery);