(function ($) {
    var $body = $('body'),
        $changeContrast = $('.js-change-contrast')
        
    if (sessionStorage.getItem('contrast') == 'true') {
        $body.addClass('contrast-body')
    }

    $changeContrast.on('click', function(e) {
        e.preventDefault();

        if ($body.hasClass('contrast-body')) {
            $body.removeClass('contrast-body')
            sessionStorage.setItem('contrast', 'false')
        }
        else {
            $body.addClass('contrast-body')
            sessionStorage.setItem('contrast', 'true')
        }
    })

})(jQuery);