(function ($) {
    var $sidebar = $('#js-floating-sidebar'),
        $sidebarLimit = $('#js-floating-sidebar-end'),
        $sidebarContent = '#js-sidebar-content',
        $sidebarFooter = '#js-sidebar-footer',
        $sidebarContainer = $('#js-sidebar-container')

    $(document).ready(function() {
        //init wysokość kontenerów
        sidebarHeight($($sidebarContent), $($sidebarFooter))
        /**
         * scroll to fix - inicjacja wtyczki
         */
        $sidebar.scrollToFixed({
            removeOffsets: true,
            marginTop: 80,
            limit: function(){
                return $sidebarLimit.offset().top - 130 - $(this).height();
            }
        });
    });

    
    /**
     * wysokość kontenerów 
     */
    function sidebarHeight(content, footer) {
        if (content.length > 0 && footer.length > 0) {
            var footerHeight = footer.outerHeight()
            content.css('maxHeight', $(window).height() - 90 - footerHeight)
        }
    }
    
    //custom scrollbar
    $(window).on('load', function(){
        $($sidebarContent).mCustomScrollbar();
    });

    var selectionName = '.js-selection-item'
    var $form = $('#nextStep');

    $(document).ready(function() {
        reloadSidebar();
    })

    $('body').on('change', selectionName, function() {
        reloadSidebar($(this).attr('data-target'));
    })

    $('body').on('internetLoaded:on', function() {
        reloadSidebar();
    })

    function reloadSidebar(scroll = false) {
        var data = {},
            step = $form.find('[name=step]').val()
            
        $(selectionName + ':checked').each(function() {
            var type = $(this).attr('type'),
                key = $(this).attr('name'),
                val = $(this).val()

            if (type == 'radio') {
                if ($(this).hasClass('js-additional-payment'))  {
                    var arr = $('[name="' + key + '"]:checked').map(function(){
                        return this.value;
                    }).get();
                    key = key.replace('[', ''),
                    key = key.replace(']', '') 
                    data[key] = arr;
                }
                else {
                    data[key] = val;
                }
            }
            else if (type == 'checkbox') {
                var arr = $('[name="' + key + '"]:checked').map(function(){
                    return this.value;
                }).get();
                key = key.replace('[', ''),
                key = key.replace(']', '') 
                data[key] = arr;
            }
        })

        $(selectionName).each(function() {
            var type = $(this).attr('type'),
                key = $(this).attr('name'),
                val = $(this).val()

            if (type == 'hidden' || type == 'number') {
                data[key] = val;
            }
        })

        ajaxSidebar(data, step, scroll);
    }

    function ajaxSidebar(data, step = 1, scrollTo = false) {
        $sidebarContainer.addClass('loading')

        $.ajax({
            url:ajax.url,
            data: {
                action: 'sidebar_load',
                form: data,
                step: step
            },
            type:'post',
            success:function(data){
                $sidebarContainer.html(data)
                sidebarHeight($('body').find($sidebarContent), $('body').find($sidebarFooter))

                if (scrollTo) {
                    $elem = $('body').find($sidebarContent)
                    $elem.mCustomScrollbar();
                    $elem.mCustomScrollbar('scrollTo', scrollTo, {
                        timeout: 0,
                        scrollInertia: 0
                    });
                }
                else {
                    $('body').find($sidebarContent).mCustomScrollbar();
                }

                $sidebarContainer.removeClass('loading')
            }
        })
        return false
    }

})(jQuery);