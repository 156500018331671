(function ($) {
    // zamykanie popupu
    $('.canal-box').click(function() {
        $(this).find('.canal-popup').addClass('active');
    })

    $('body').on('click', '.canal-popup.active', function() {
        $(this).removeClass('active');
    })

    $( function() {
        $("#tabs").tabs({
            show: { 
                effect: "fade", 
                duration: 300 ,
                select: function(event, ui) {
                    window.location.hash = ui.tab.hash;
                }
            }
        });
    });
  
})(jQuery);