(function ($) {
    var toggler = '.js-toggle-section',
        toggled = '.js-toggled-section'

    $(toggler).on('change', function() {
        var that = $(this),
            target = that.attr('data-target'),
            parent = that.attr('data-parent')

        $(parent).find(toggled).each(function() {
            $(this).hide();
        })

        $(parent).find(target).show();
    })
})(jQuery);