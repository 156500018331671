(function ($) {
    const popupId = 'js-popup-card';
    const popupCloseId = 'js-popup-card-close';
    const popup = document.getElementById(popupId);
    const activeClass = 'active';
    const cookieName = 'popup_close';
    setTimeout(() => {
        if (popup && !popup.classList.contains(activeClass)) {
            popup.classList.add(activeClass)
        }
    }, 500)
    if (document.getElementById(popupCloseId)) {
        document.getElementById(popupCloseId).addEventListener('click', (e) => {
            e.preventDefault()
            if (popup) {
                popup.classList.remove(activeClass)
            }
        })
    }
    // Magnific popup
    $('.popup-with-zoom-anim').magnificPopup({
		type: 'inline',

		fixedContentPos: false,
		fixedBgPos: true,

		overflowY: 'auto',

		closeBtnInside: true,
		preloader: false,
		
		midClick: true,
		removalDelay: 300,
		mainClass: 'my-mfp-zoom-in'
    });

    var $trigger = $('#js-non-available-popup');

    $(document).ready(function() {
        if ($trigger.length > 0) {
            $trigger.trigger('click')
            var slug = $trigger.attr('data-target')
            window.history.replaceState({}, document.title, '/' + slug + '/');
        }
    });


})(jQuery);