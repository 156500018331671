(function ($) {
    // home slider
    var slider = $('#js-home-slider')
    var sliderNav = '.js-home-slider-nav'

    slider.slick({
        dots: false,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true
                }
            }
        ]
    });

    $(sliderNav).on('click', function() {
        slider.slick('slickGoTo', $(this).attr('data-target'))
    })
    
    slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $(sliderNav).each(function(){
            $(this).removeClass('active-slide')
        })
        $(sliderNav + '[data-target="' + nextSlide + '"]').addClass('active-slide')
    });
  
})(jQuery);