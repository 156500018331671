(function ($) {
    /**
     * ajax - ładowanie pakietów internetowych
     */
    var $radioSelect = '.js-radio-select',
        $packagesContainer = $('#js-ajax-packages')

    $($radioSelect).on('change', function(e) {
        $packagesContainer.addClass('loading')
        var technology = $(this).val(),
            client_type = $('[name="package_client_type"]').val()

        _ajaxLoad(technology, client_type) 
    })
    
    function _ajaxLoad(technology, client_type) {
        $.ajax({
            url:ajax.url,
            data: {
                action: 'packages_load',
                technology: technology,
                client_type: client_type
            },
            type:'post',
            success:function(data){
                $packagesContainer.html(data).removeClass('loading');
                $('body').trigger( "internetLoaded:on" );
            }
        })
        return false
    }

    /**
     * ajax - zapisywanie formualrza
     */
    var $configForm = $('#js-ajax-config-submit')

    $configForm.on('submit', function(e) {
        e.preventDefault();
        _ajaxSubmit($(this).find('[name="config"]').val());
    })
    

    function _ajaxSubmit(form) {
        $.ajax({
            url:ajax.url,
            data: {
                action: 'submit_form',
                form: JSON.parse(form)
            },
            type:'post',
            success:function(data){
                window.location.href = data;
                // console.log(data);
            }
        })
        return false
    }

    //additional payments

    var additionalPayment = $('.js-additional-price')

    additionalPayment.change(function() {
        var that = $(this)
        var type = that.attr('type')
        if (type == 'radio') {
            additionalPayment.each(function() {
                additionalPayments($(this))
            })
            setTimeout(function() {
                additionalPayments(that)
            }, 100)
        }
        else {
            additionalPayments(that)
        }
    })

    $(document).ready(function() {
        additionalPayment.each(function() {
            additionalPayments($(this))
        })
    })

    function additionalPayments(trigger) {
        var parent = trigger.attr('id')
        if (trigger.is(':checked')) {
            $('body').find('[data-parent="' + parent  + '"]').prop('checked', true).trigger('change').parents('.big-radio').removeClass('hidden');
        }
        else {
            $('body').find('[data-parent="' + parent  + '"]').prop('checked', false).trigger('change').parents('.big-radio').addClass('hidden');
        }
    }

    var selectionName = $('.js-selection-item')

    selectionName.change(function() {
        excludePackages($(this))
    })

    $(document).ready(function() {
        selectionName.each(function() {
            excludePackages($(this))
        })
    })

    function excludePackages(trigger) {
        $that = trigger
        var exclude = $that.attr('data-exclude')

        if (exclude) {
            var excluded = exclude.split(',')

            excluded.forEach(function(element, index) {
                if ($that.is(':checked')) {
                    $('body').find('[value="' + element + '"]').prop('checked', false).trigger('change').parents('.big-radio').addClass('big-radio--disabled')
                }
                else {
                    $('body').find('[value="' + element + '"]').parents('.big-radio').removeClass('big-radio--disabled')
                }
            })
        }
    }

})(jQuery);